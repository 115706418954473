import '@/plugin';
import '@/styles/index.scss';

import { createGtm } from '@gtm-support/vue-gtm';
import VConsole from 'vconsole';
import { createApp } from 'vue';

import App from '@/App.vue';
import { landingPageInjectionKey } from '@/hooks/use_landing_page';
import { campaign, loadLandingPageJson } from '@/json/json_loader';
import { errorHandlerInstallWith } from '@/plugin/error_handler';
import { VuePlugin } from '@/plugin/vue_plugin';
import { createLandingPageRouter } from '@/router';
import { isDebug } from '@/utils/debug';
import { setupActions } from '@/utils/setup_actions';
import { setupContents } from '@/utils/setup_contents';

import { setupRum } from './hooks/use_rum';
import { handleLpTarget } from './utils/handleLpTarget';

async function main() {
  // RUM を準備する
  const rum = setupRum(campaign.rumConfig);

  // 各コンテンツを利用できるようにする
  setupContents();
  setupActions();

  const landingPage = loadLandingPageJson();

  if (await handleLpTarget(landingPage)) {
    return;
  }

  const app = createApp(App);
  const router = createLandingPageRouter(landingPage);
  app.use(router);
  await router.isReady();
  if (campaign.googleTagManagerIds.length > 0) {
    app.use(createGtm({ id: campaign.googleTagManagerIds }));
  }
  app.use(VuePlugin);
  app.use(errorHandlerInstallWith(router, rum));

  // アプリレベルで Provide しておく
  app.provide(landingPageInjectionKey, landingPage);

  app.mount('#app');

  // デバッグ用ツールの初期化。本番では消すこと！
  if (isDebug()) {
    new VConsole();
  }
}

main();

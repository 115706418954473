import { ActionBase } from '@/models/action_base';
import { KbnConst } from '@/utils/kbn';

/**
 * カスタマイズアクション
 */
export class ActionCustomizing extends ActionBase {
  typeKbn = KbnConst.ACTION_TYPE_KBN_CUSTOMIZING;

  keyName?: string;

  use() {
    return async () => await execute(this.keyName);
  }

  constructor(init: Partial<ActionCustomizing> = {}) {
    super();
    Object.assign(this, init);
  }
}

async function execute(
  keyName: string | undefined,
) {
  // キー名を使って好きな処理を実行できる
  alert(`このアクションは未実装（キー名: ${ keyName }）`);
  return false;
}

export function generateActionCustomizing(keyName?: string): ActionCustomizing {
  return new ActionCustomizing({
    keyName,
  });
}

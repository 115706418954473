import { plainToClass } from 'class-transformer';
import * as convertKeys from 'convert-keys';

import { api } from '@/api';
import { Api } from '@/api/api';
import { RECEIPT_RESULT_CODE, RESULT_CODE } from '@/api/result_code';
import surveyJson from '@/json/survey.json';
import { SurveyPattern } from '@/models/survey_pattern';
import { User } from '@/models/user';
import { debugLog } from '@/utils/debug';
import { sleep } from '@/utils/sleep';

let apiMock: Api | undefined = undefined;

export function useApi() {
  if (apiMock) return apiMock;
  return api;
}

const apiDefaultMock: Partial<Api> = {
  async getLineLogin() {
    await sleep(500);
    return {
      resultCode: RESULT_CODE.SUCCESS,
      isLogin: true,
      loginUrl: 'https://example.com',
    };
  },
  async getLineLoginOpenid() {
    await sleep(500);
    return {
      resultCode: RESULT_CODE.SUCCESS,
      isLogin: true,
      loginUrl: 'https://example.com',
    };
  },
  async postLineLogin() {
    await sleep(500);
    return {
      resultCode: RESULT_CODE.SUCCESS,
      trackingId: 'test_tracking',
      dataJson: { 'test': 'test' },
    };
  },
  async postCheckinLogin() {
    await sleep(500);
    return {
      resultCode: RESULT_CODE.SUCCESS,
      trackingId: 'test_tracking',
      dataJson: { 'test': 'test' },
    };
  },
  async postLineLoginOpenid() {
    await sleep(500);
    return {
      resultCode: RESULT_CODE.SUCCESS,
      trackingId: 'test_tracking',
      dataJson: { 'test': 'test' },
    };
  },
  async postInsert(...args) {
    debugLog('postInsert args', args);
    return {
      resultCode: RESULT_CODE.SUCCESS,
      entryUuid: 'test_uuid',
    };
  },
  async postInsertOpenid(...args) {
    debugLog('postInsertOpenid args', args);
    return {
      resultCode: RESULT_CODE.SUCCESS,
      entryUuid: 'test_uuid',
    };
  },
  async getUsers() {
    await sleep(500);
    const surveyJsonToCamel = plainToClass(SurveyPattern, convertKeys.toCamel<unknown>(surveyJson));
    return {
      resultCode: RESULT_CODE.SUCCESS,
      dataJson: {
        isFriend: true,
        survey: surveyJsonToCamel,
        redirectPage: '',
        amount: 1000,
        mileage: {
          point: 100,
          pointHistories: [
            {
              label: 'application',
              entriedAt: '2000/01/01 00:00:00',
            },
            {
              label: 'application',
              entriedAt: '2010/01/01 00:00:00',
            },
            {
              label: 'receipt',
              entriedAt: '2000/01/01 00:00:00',
            },
          ],
        },
      } as unknown as User,
    };
  },
  async getCommon() {
    await sleep(500);
    return {
      resultCode: RESULT_CODE.SUCCESS,
      dataJson: {
        isInStock: true,
      },
    };
  },
  async getLottery() {
    await sleep(500);
    return {
      resultCode: RESULT_CODE.SUCCESS,
    };
  },
  async getEntryPolling() {
    return {
      resultCode: RESULT_CODE.SUCCESS,
      responseCandidateUuid: 'test_response_candidate_1',
      timeSeedingId: 'string',
      serialCode: 'test_serial',
    };
  },
  async postReceipt(...args) {
    debugLog('postReceipt args', args);
    await sleep(1000);
    return {
      resultCode: RECEIPT_RESULT_CODE.SUCCESS,
      message: 'モック',
      data: { uuid: 'test' },
    };
  },
  async postAnonymousLogin() {
    return {
      resultCode: RECEIPT_RESULT_CODE.SUCCESS,
      message: 'モック',
      trackingId: 'aaa',
    };
  },
  async getReceipt() {
    return {
      resultCode: RECEIPT_RESULT_CODE.RUNNING,
      message: 'モック',
      data: 'test',
    };
  },
  async getReceiptPolling() {
    await sleep(1000);
    return {
      resultCode: RECEIPT_RESULT_CODE.SUCCESS,
      message: 'モック',
      data: 'test',
    };
  },
  async postLiffLogin() {
    return {
      resultCode: RECEIPT_RESULT_CODE.SUCCESS,
      entryUuid: 'string',
      isHitable: true,
      trackingId: '000000000000',
    };
  },
  async postLiffLoginOpenid() {
    return {
      resultCode: RECEIPT_RESULT_CODE.SUCCESS,
      entryUuid: 'string',
      isHitable: true,
      trackingId: '000000000000',
    };
  },
  async getReceiptUploadUrl() {
    await sleep(1000);
    return {
      resultCode: RECEIPT_RESULT_CODE.SUCCESS,
      message: 'モック',
      dataJson: {
        receiptUuid: '11111111-1111-1111-1111-111111111111',
        presignedUrl: 'https://www.google.com/',
      },
    };
  },
  async uploadFile(...args) {
    debugLog('uploadFile args', args);
    await sleep(5000);
  },
};

// eslint-disable-next-line object-curly-newline
export function useMockApi(target: Partial<Api> = {}): Api {
  const handler = {

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get: function(target: Partial<Api>, handler: keyof Api, receiver: any) {
      if (Object.keys(target).indexOf(handler) !== -1) {
        return Reflect.get(target, handler, receiver);
      }
      if (Object.keys(apiDefaultMock).indexOf(handler) !== -1) {
        return Reflect.get(apiDefaultMock, handler, receiver);
      }

      // return Reflect.get(api, handler, receiver);
      throw new Error(`API：\`${handler}\`はこのテストで未実装。`);
    },
  };

  apiMock = new Proxy(target, handler) as Api;

  return apiMock;
}

import dayjs from 'dayjs';

import { Campaign } from '@/models/campaign';
import { ResponseCandidate } from '@/models/response_candidate';

const staging = new Campaign({
  campaignUuid: '', // TODO: 設定する
  campaignStartUts: dayjs.tz('2000-01-01 00:00:00', 'Asia/Tokyo'),
  campaignEndUts: dayjs.tz('2100-01-01 00:00:00', 'Asia/Tokyo'),
  liffId: '', // TODO: 設定する
  clientBasicId: '', // TODO: 設定する
  isOnlyOpenid: false, // TODO: 設定する
  googleTagManagerIds: [],
  escapeUrl: 'https://google.com', // TODO: 設定する
  responseCandidates: [

    // TODO: 設定する
    new ResponseCandidate({
      name: '返信１',
      uuid: 'test_response_candidate_1',
      pageName: 'page1',
    }),
    new ResponseCandidate({
      name: '該当なし',
      uuid: '',
      pageName: 'error',
    }),
  ],

  // TODO: liff.sendMessage を利用する場合には記述する
  // liffSendMessages: [
  //   {
  //     type: 'text',
  //     text: 'receiptUuid: <%= insertDataStore.receiptUuid.value %>',
  //   },
  // ],
});

const prod = new Campaign({
  campaignUuid: '', // TODO: 設定する
  campaignStartUts: dayjs.tz('2022-04-01 00:00:00', 'Asia/Tokyo'), // TODO: 設定する
  campaignEndUts: dayjs.tz('2022-05-01 00:00:00', 'Asia/Tokyo'), // TODO: 設定する
  liffId: '', // TODO: 設定する
  clientBasicId: '', // TODO: 設定する
  isOnlyOpenid: false, // TODO: 設定する
  googleTagManagerIds: [],  // TODO: 設定する
  escapeUrl: 'https://google.com', // TODO: 設定する
  responseCandidates: [

    // TODO: 設定する
    new ResponseCandidate({
      name: '返信１',
      uuid: 'test_response_candidate_1',
      pageName: 'page1',
    }),
    new ResponseCandidate({
      name: '該当なし',
      uuid: '',
      pageName: 'error',
    }),
  ],

  // TODO: RUM を新規作成し「設定 > JavaScript スニペット」を参照して、必要な値を以下に貼り付ける
  // 本番環境では必ず設定する
  // rumConfig: new RumConfig({
  //   applicationId: '11111111-1111-1111-1111-111111111111',
  //   identityPoolId: 'ap-northeast-1:22222222-2222-2222-2222-222222222222',
  // }),
});

export const campaignSettings = (() => {
  switch (process.env.VUE_APP_ENV) {
  case 'staging': return staging;
  case 'prod': return prod;
  default: return staging;
  }
})();
